.control {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 33px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 16px;
    max-width: 600px;
}

.control.disabled{
    cursor: auto;
}

.control.hide-square {
    pointer-events: none;
}

.control.uppercase {
    text-transform: uppercase;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control_indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background: transparent;
    border: 2px solid #16BECF;
    border-radius: 5px;
}
.with_label {
    position: absolute;
    top: 0;
    left: 0;
}
.control:hover input ~ .control_indicator {
    /*background: #16BECF;*/
}

.control input:checked ~ .control_indicator {
    background: #16BECF;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #16BECF;
}
.control input:disabled ~ .control_indicator {
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    text-transform: lowercase;
    line-height: 1px;
    content: "x";
    color: #ffffff;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: flex;
}
.control-checkbox .control_indicator:after {
    content: "x";
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}
