* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif !important;
}

*:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.MuiSnackbarContent-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Toastify .Toastify__toast-container {
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 18rem;
  padding: 0;
}

.Toastify .Toastify__toast-container .Toastify__toast {
  font-family: inherit;
  min-height: 3.0625rem;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #000;
}

.Toastify .Toastify__toast-container .Toastify__toast--error {
  color: #9f4f48;
  background: #efdfdf;
  border-color: #e7cecd;
}

.Toastify .Toastify__toast-container .Toastify__toast--warning {
  color: #e0b53f;
  background: #f5f2e4;
  border-color: #dedcc3;
}

.Toastify .Toastify__toast-container .Toastify__toast--success {
  color: #35a638;
  background: #ddf3e0;
  border-color: #bddbc9;
}

.Toastify .Toastify__toast-container .Toastify__toast--info,
.Toastify .Toastify__toast-container .Toastify__toast--default {
  color: #5b9ce0;
  background: #cce9f5;
  border-color: #adc2de;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button {
  align-self: center;
  opacity: 0.5;
  margin: 0 0.7em;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button:hover {
  background: none;
  opacity: 1;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button--error {
  color: #9f4f48;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button--warning {
  color: #e0b53f;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button--success {
  color: #35a638;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button--info,
.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button--default {
  color: #5b9ce0;
}

.Toastify__toast-container--bottom-center {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
}
