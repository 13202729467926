.my-popover {
    margin-left: 1rem;
}

.my-paper {
    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 3px !important;
    border: 2px solid #1BD0E2 !important;
    margin-bottom: 0.5rem !important;
    background: #1BD0E2 !important;
    color: white !important;
    max-width: 300px !important;
    text-align: justify !important;
}
